import React from "react";
import uniqid from "uniqid";
import { works } from "../../portfolio";
import WorksContainer from "../ProjectContainer/ProjectContainer";
import "./Works.css";

const Works = () => {
	if (!works.length) return null;

	return (
		<section id="works" className="section projects">
			<h2 className="section__title">Past Works</h2>

			<div className="projects__grid">
				{works.map((project) => (
					<WorksContainer key={uniqid()} project={project} />
				))}
			</div>
		</section>
	);
};

export default Works;
