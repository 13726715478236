import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../contexts/theme";
import Header from "../aboutcomponents/Header/Header";
import Aboutpage from "../aboutcomponents/About/About";
import Projects from "../aboutcomponents/Projects/Projects";
import Works from "../aboutcomponents/Works/Works";
import Freelancer from "../aboutcomponents/Freelance/Freelance";
import Skills from "../aboutcomponents/Skills/Skills";
import ScrollToTop from "../aboutcomponents/ScrollToTop/ScrollToTop";
import Contact from "../aboutcomponents/Contact/Contact";
import Footer from "../aboutcomponents/Footer/Footer";
import "../App.css";
import { Helmet } from "react-helmet";
//import "../index.css";

const About = () => {
	const [{ themeName }] = useContext(ThemeContext);

	return (
		<div id="top" className={`${themeName} app`}>
			<Helmet
				style={[
					{
						cssText: `
				* {
					padding: 0;
					margin: 0;
					border: 0;
					outline: 0;
					background-color: inherit;
					color: inherit;
					font-family: inherit;
					font-size: inherit;
					box-shadow: none;
					box-sizing: border-box;
				}
				
				html {
					scroll-behavior: smooth;
				}
				
				h1,
				h2,
				h3,
				h4 {
					line-height: 1.2;
					color: var(--clr-fg-alt);
				}
				
				h1 {
					font-size: 4rem;
				}
				
				h2 {
					font-size: 2rem;
				}
				
				h3 {
					font-size: 1.5rem;
				}
				
				h4 {
					font-size: 1.3rem;
				}
				
				ul {
					list-style-type: none;
				}
				
				a {
					text-decoration: none;
				}
				
				button {
					cursor: pointer;
				}
				
				@media (max-width: 900px) {
					h1 {
						font-size: 2.6rem;
					}
				}
				`,
					},
				]}
			/>
			<Header />
			<main>
				<Aboutpage />
				<Freelancer />
				<Works />
				<Projects />
				<Skills />
				<Contact />
			</main>

			<ScrollToTop />
			<Footer />
		</div>
	);
};

export default About;
