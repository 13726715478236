import React from "react";
import uniqid from "uniqid";
import { freelance } from "../../portfolio";
import FreelanceContainer from "../ProjectContainer/ProjectContainer";
import "./Freelance.css";

const Freelancer = () => {
	if (!freelance.length) return null;

	return (
		<section id="freelance" className="section projects">
			<h2 className="section__title">Freelancing</h2>

			<div className="projects__grid">
				{freelance.map((project) => (
					<FreelanceContainer key={uniqid()} project={project} />
				))}
			</div>
		</section>
	);
};

export default Freelancer;
